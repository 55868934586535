import * as React from "react"

import MainMenu from "../components/MainMenu/Mainmenu"
import SectionTen from "../components/SectionTen/SectionTen"
import Seo from "../components/seo"

import signature from "../images/images/signature.jpg"
import choose from "../images/images/choose.jpg"

const Website= () => (
    <>
    <Seo title="Website" />
    <MainMenu id="whiteBg"/>
    <section class="chooseus home_page2" id="chooseus">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <div className="sectionTitle bigTitle2">
                            <h5 className="magenta text-uppercase bold">why choose us?</h5>
                            <h2 className="lead">we dont just build websites, we build brands.</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-5">
                        <div className="chooseUsContent">
                            <h3 className="magenta normal">Our team works shoulder-to- shoulder, ensuring a project is never created in isolation.</h3>
                            <p>Every project has its own unique goals and vision. Whatever your project demands, our extended network of strategists, creatives and technology specialists is always eager to pitch in. We know it helps to know good people.</p>
                            <div className="signatureandname">
                                <h4>Richie Meldrum</h4>
                                <img src={signature} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="imagesDiv chooseUsImg">
                            <img src={choose} alt="" />
                        </div>
                    </div>
                </div>
            </div>
       </section>
       <SectionTen/>
    </>
)
export default Website